import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { trackEvent } from 'ddc-track-event';
import {
	useLabels,
	useSitemap,
	useRequestData
} from 'ws-scripts/modules/common';

import { isSitemapEntry } from '../utils/isSitemapEntry';
import { constructURLParams } from '../utils/constructURLParams';

const Description = ({
	count,
	title,
	linkHref,
	linkText,
	linkTarget,
	description = 'BLANK'
}) => {
	const labels = useLabels();
	const sitemap = useSitemap();

	const { widgetName, windowId } = useRequestData();
	const vehicleInfo = useSelector((state) => state.vehicleInfo);

	const isDialog = linkTarget === 'dialog';
	const isScrollToLink = linkHref ? linkHref.startsWith('#') : false;
	const resolvedLinkHref = isSitemapEntry(linkHref)
		? sitemap.get(linkHref)
		: linkHref;

	// Adding unique URL param due to cms-web restricting updates to
	// dialog titles when multiple CTAs request the same URL path
	const urlParams = { ...vehicleInfo, c: count };

	return (
		<div
			className="d-flex flex-column justify-content-center w-75 p-4"
			data-testid="Description"
		>
			<h4 className="mt-0 mx-0 mb-3">{labels.get(title)}</h4>
			<p className="mt-0 mx-0 mb-3">{labels.getHTML(description)}</p>
			<a
				href={constructURLParams(
					resolvedLinkHref,
					urlParams,
					isScrollToLink
				)}
				className={`text-link-muted text-decoration-underline
					${isDialog && !isScrollToLink ? ' dialog' : ''}`}
				data-title={
					isDialog && !isScrollToLink ? labels.get(linkText) : null
				}
				target={!isDialog && !isScrollToLink ? linkTarget : null}
				onClick={() =>
					trackEvent(widgetName, windowId, {
						element: 'CTA Button/Link',
						elementCTA: labels.get(linkText),
						result: 'Navigated to CTA destination'
					})
				}
			>
				{labels.get(linkText)}
			</a>
		</div>
	);
};

Description.defaultProps = {
	description: 'BLANK'
};

Description.propTypes = {
	count: PropTypes.number.isRequired,
	linkHref: PropTypes.string.isRequired,
	linkTarget: PropTypes.string.isRequired,
	linkText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string
};

export default Description;
